@import-normalize;
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed|Roboto:300,400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@200;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Neuton:wght@200&display=swap');

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    background-color: rgb(30, 30, 30);
}

body {
    /* background: url(./assets/RasterDot_5x5px.svg), linear-gradient(to bottom,#c4c4c4 0,#fff 100%);
    background-size: 5px;
    background-repeat: repeat; */
    font-family: 'Roboto', sans-serif;
}

li {
    list-style: none;
}
